@charset "utf-8";

@import '../../_inc/scss/variables.scss';
@import '../../_inc/scss/mixins.scss';

.global-footer {
  margin-top: 50px;
}

.copyright {
  margin: 0;
  padding: 0.5em;
  background: #000;
  color: #fff;
  line-height: 1;
}