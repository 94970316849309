@charset "utf-8";

@import '../../_inc/scss/variables.scss';
@import '../../_inc/scss/mixins.scss';

html, body {
  // height: 100%;
}

body {
  color: $text_color;
  font-family: "游ゴシック体 Medium", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", arial, sans-serif;
  line-height: 1.6;
}

img {
  height: auto;
  vertical-align: bottom;
}

// utilities
@import '_utilities';
// header
@import '_header';
// content
@import '_content';
// footer
@import '_footer';
