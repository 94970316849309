@charset "utf-8";

@import '../../_inc/scss/variables.scss';
@import '../../_inc/scss/mixins.scss';

.main {
  min-height: 500px;
  @include mq() {
    border-top: 1px solid #ddd;
  }
}
