@charset "utf-8";

@import '../../_inc/scss/variables.scss';
@import '../../_inc/scss/mixins.scss';

.wf-sawarabimincho {
  font-family: "Sawarabi Mincho";
}

.container {
  width: 100%;
}

.inner {
  margin: auto;
  max-width: 1200px;
  width: 95%;
}

.btn {
  display: inline-block;
  padding: 0.75em 2em;
  background: none;
  border: 2px solid $text_color;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
  }
  &.btn-forward {
    background: $text_color;
    color: #fff;
  }
  &.btn-back {
    color: $text_color;
  }
}

.btn-group {
  .btn {
    @include mq(sp) {
      display: block;
      margin-bottom: 0.875rem;
    }
  }
}

.list-gender {
  padding: 0;
  text-align: center;
  > li {
    display: inline-block;
    margin: 0 0.5rem;
  }
  input[name="gender"] {
    margin-right: 0.5rem;
  }
}

.list-switch__type {
  padding: 0;
  a {
    display: block;
    padding: 0.5rem 1.25rem;
    border: 2px solid $text_color;
    color: $text_color;
    text-decoration: none;
    &.current,
    &:hover {
      background: $text_color;
      color: #fff;
    }
  }
  > li {
    &:nth-child(1) {
      a {
        border-right-width: 1px;
        border-radius: 3px 0 0 3px;
      }
    }
    &:nth-child(2) {
      a {
        border-left-width: 1px;
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

.form-group__convert {
  margin: auto;
  max-width: 600px;
}

.input-convert__text {
  padding: 0.5rem;
  width: 80%;
}

.btn-convert {
  width: 20%;
  background: $text_color;
  border: none;
  border-radius: 0 3px 3px 0;
  color: #fff;
}

.error-message {
  margin: 1.5rem auto;
  padding: 0.5rem;
  border: 3px solid $red;
  color: $red;
  @include mq() {
    margin: 1.5rem auto;
    max-width: 600px;
  }
}

.list-error {
  margin: 0;
  padding-left: 1.5em;
}

.is-loading {
  // height: 100%;
  // overflow: hidden;
}

.loading-overlay {
  position: absolute;
  top: 0;
  display: none;
  overflow: hidden;
  width: 100%;
  // height: 100%;
  background: rgba(0, 0, 0, .6);
  z-index: 5555;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loadinganime 1.1s infinite linear;
  animation: loadinganime 1.1s infinite linear;
}

@-webkit-keyframes loadinganime {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadinganime {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}