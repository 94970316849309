@charset "utf-8";

@import '../../_inc/scss/variables.scss';
@import '../../_inc/scss/mixins.scss';

.drawer-navbar {
  border-bottom: none;
}

.drawer-container {
  max-width: 1200px;
  border-bottom: none;
}